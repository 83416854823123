import { SET_COURSE } from './-mutation-types';
import { useCoursesStore } from '~/stores/courses';
import { type Course } from '~/models/Course';

const Mutations = {
    [SET_COURSE](payload: Course | null) {
        const coursesStore = useCoursesStore();
        coursesStore.course = payload;
    },
};

export default Mutations;
