import { GET_COURSE } from './-getter-types';
import { useCoursesStore } from '~/stores/courses';
import { type Course } from '~/models/Course';

const Getters = {
    [GET_COURSE]: (): Course | null => {
        const coursesStore = useCoursesStore();

        return coursesStore.course;
    },
};

export default Getters;
