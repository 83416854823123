import { SET_COURSE } from './-mutation-types';
import { useCoursesStore } from '~/stores/courses';
import CourseService from '~/services/api/crm/CourseService';
import type { Course } from '~/models/Course';

const Actions = {
    async fetchCourse(courseId: number) {
        const coursesStore = useCoursesStore();
        const courseService = new CourseService();
        const response = await courseService.fetchCourse(courseId);

        coursesStore[SET_COURSE](response);
    },
    setCourse(course: Course | null) {
        const coursesStore = useCoursesStore();
        coursesStore[SET_COURSE](course);
    },
};

export default Actions;
